import axios from "axios";
import {httpUrl} from './config'
import {message} from "antd";
import {loginOut} from "./common/common";


let makeRequest = axios.create({
  baseURL: httpUrl,
  withCredentials: true,
  headers:{'Content-Type':'application/json'}
});
makeRequest.interceptors.response.use(function(res){
  return new Promise((resolve, reject)=>{
    const code=res.status.toString();
    switch (code){
      case '200':
        return resolve(res);
      case '401':
        void message.error("用户没找到");
        loginOut();
        reject()
        break
      case '400':
        void message.error("账号密码错误");
        break
      case '500':
        void message.error("接口出错了");
        break
    }
    return reject(res);
  }).catch((reason)=>{
    console.error("接口出错",reason)
  })

},(error)=>{
  return new Promise((resolve, reject)=>{
    const code=error?.response?.status?.toString();
    switch (code){
      case '401':
        void message.error("用户没找到");
        loginOut();
        return reject("用户没找到")
      case '400':
        void message.error("账号密码错误");
        break
      case '500':
        void message.error("接口出错了");
        break
      case '305':
      void message.warning("推送失败");
    }
    reject(error);
  })

})
export default makeRequest;