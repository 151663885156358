import { useContext, useState } from "react";
import { AuthContext } from "../../context/authContext";
import "./login.scss";

const Login = () => {
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });
  const [err, setErr] = useState(null);
  const handleChange = (e) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const { login } = useContext(AuthContext);
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      login(inputs);
    } catch (err) {
      setErr(err.response.data);
    }
  };

  return (
    <div className="login">
      <div className="card">
        <div className="left">
          <h1>你好</h1>
          <p>
            余幼时即嗜学。家贫，无从致书以观，每假借于藏书之家，手自笔录，计日以还。
          </p>
          {/*<span>没有账号去注册</span>*/}
          {/*<NavLink to="/register">*/}
          {/*  <button>Register</button>*/}
          {/*</NavLink>*/}
        </div>
        <div className="right">
          <h1>登录</h1>
          <form>
            <input
              type="text"
              placeholder="请输入用户名"
              name="username"
              onChange={handleChange}
            />
            <input
              type="password"
              placeholder="请输入密码"
              name="password"
              onChange={handleChange}
            />
            <div style={{color:"red",fontSize:'12px'}}>{err && err}</div>
            <button onClick={handleLogin}>Login</button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
