import "./index.scss"
import {useCallback, useEffect, useRef, useState} from "react";
import {Button, Card, Input, Modal} from "antd";
import makeRequest from "../../../axios";
import {httpUrl} from "../../../config";
const CurrencyAddress = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [data,setData]=useState([]);
    const resApi=()=>{
        const user=JSON.parse(window.localStorage.getItem('user'));
        if(!user?.manageId){
            void  modal.warning("找不到登录信息manageId")
        }
        makeRequest.post(`${httpUrl}app/getCurrencyAddress`, {manageId:user?.manageId}, {
            withCredentials: true,
        }).then((res)=>{
            setData(res?.data||[]);
        }).catch(()=>{
            setData([]);
        })
    };
    useEffect(()=>{{
        resApi()
    }},[]);
    const inputValue=useRef();
    const updateApi=(data)=>{
        makeRequest.post(`${httpUrl}app/updateCurrencyAddress`, {...data}, {
            withCredentials: true,
        }).then((res)=>{
            modal.success({title: '推送',content:res.data});
            resApi();
        })
    }
    const updateDizhi=(item)=>{
        modal.confirm({
            title: item.name+'修改地址',
            content:<Input defaultValue={item.url} onChange={e => inputValue.current = e.target.value} />,
            okText: '确认',
            cancelText: '取消',
            onOk:()=>{
                updateApi({id:item.id,url:inputValue.current})
            },
        });
    }
    return (
        <div className="currencyAddress">
            {contextHolder}
            {
                data.map((item,index)=>{
                    return (
                        <div key={index} className="cardBox">
                            <Card  title={item?.name} content={item.url}>
                                {item?.url}
                                <Button style={{marginLeft:20,marginTop:30}} onClick={()=>updateDizhi(item)}>修改地址</Button>
                            </Card>
                        </div>
                       )
                })
            }
        </div>
    )
}

export default CurrencyAddress