import {httpUrl, httpUrlImg} from "../config";

export const loginOut=()=>{
    console.log("要执行退出登录了")
    // window.location.replace("/login");
}
export const getImgUrl=(url)=>{
    const src=url?url?.substring(7,url?.length):'';
    return  (httpUrlImg||httpUrl||'')+src;
}
export const getImgUrlDate=(url)=>{
    return url?url?.substring(8,url.lastIndexOf('/')):'';
}