import "./index.scss"
import {useCallback, useEffect, useMemo, useState} from "react";
import {Pagination, Image, Card, Checkbox, Button, message} from "antd";
import makeRequest from "../../../axios";
import {httpUrl} from "../../../config";
import {getImgUrl, getImgUrlDate} from "../../../common/common";
const AppUserImage = () => {
    const [pageData,setPageData]=useState({total: 0, page: 1, pageSize: 1000});
    const [dataSource,setDataSource] =useState( []);
    const [checkedList, setCheckedList] = useState([]);
    const [lodaing,setLodaing] = useState(false);
    const checkAll =useMemo(()=>{
        return checkedList?.length===dataSource?.length
    },[dataSource,checkedList]);
    const indeterminate = useMemo(()=>{
        return checkedList?.length > 0 && checkedList?.length < dataSource?.length;
    },[dataSource,checkedList])
    const onChange =useCallback( (e) => {
        const target=e.target;
        if(target?.checked){
            setCheckedList(()=>{return [...checkedList,target?.value]})
        }else{
            setCheckedList(()=>{return checkedList?.filter(item=>item!==target?.value)})
        }
    },[checkedList])
    const onCheckAllChange= (e) => {
        setCheckedList(e.target.checked ? dataSource?.map((item)=>item.id)||[] : []);
    };

    const resApi=(p)=>{
        setLodaing(true);
        makeRequest.post(`${httpUrl}app/getListUserImage`, p, {
            withCredentials: true,
        }).then((res)=>{
            setLodaing(false)
            setDataSource(res?.data?.data||[]);
            setPageData((value)=>{
                return {...value,total:res?.data?.total||0}
            });
        }).catch(()=>{
            setDataSource([]);
            setLodaing(false)
        })
    };

    useEffect(()=>{{
        resApi(pageData)
    }},[])

    const pageOnChang=useCallback((page,pageSize)=>{
        onCheckAllChange({target:{checked:false}})
        const p={...pageData,page,pageSize};
        setPageData(p);
        resApi(p)
    },[dataSource])

    const deleteImage=useCallback(
        ()=>{
            if(checkedList?.length<0){
                void message.error('选择可删除的图片');
                return
            }
            makeRequest.post(`${httpUrl}app/deleteFile`, {urlList:checkedList}, {
                withCredentials: true,
            }).then((res)=>{
               void message.success('删除成功');
                resApi(pageData)
            }).catch(()=>{
                void message.error('删除失败');
            })
        },[checkedList,pageData]

    )
    return (
        <div className="appUserImage">
            <div className="paginationBox">
                <Pagination
                    disabled={lodaing}
                    hideOnSinglePage
                    total={pageData.total}
                    pageSize={pageData.pageSize}
                    onChange={pageOnChang}
                    pageSizeOptions={[1000,2000,3000,4000,5000,10000]}
                />
                <div className={'checkBox'}>
                    <Checkbox value="all" indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>全选</Checkbox>
                    <div className={"checkBoxLen"}>{checkedList?.length>0?`选中${checkedList?.length}个`:''}</div>
                    <Button type={'primary'} onClick={deleteImage} danger>删除选中</Button>
                </div>
            </div>
            <Card loading={lodaing}>
                <div className={'imgMainBox'}>
                    <Image.PreviewGroup>
                        {dataSource.map((item,index)=>{
                            return <div key={index} className={'imgBox'}>
                                <Card>
                                    <div className={'cardHerderBox'}>
                                        {getImgUrlDate(item?.img_url)}
                                        <Checkbox checked={checkedList?.find((t)=>t===item?.id)} onChange={onChange} value={item?.id}></Checkbox>
                                    </div>
                                    <Image height={250} width={165} src={getImgUrl(item?.img_url)} />
                                </Card>
                            </div>
                        })}
                    </Image.PreviewGroup>
                </div>
            </Card>
        </div>
    )
}

export default AppUserImage