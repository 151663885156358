import "./home.scss"
import Menu from "../../components/menu/Menu"
import {useState} from "react";
import { WsContextProvider } from "../../context/wsContext";
const Home = () => {
  const [componentsDom,setComponentsDom]=useState();
  const onClickMenu=(e)=>{
    setComponentsDom(e)
  }
  return (
      <WsContextProvider>
          <div className="home">
              <Menu onClickFunc={(e)=>onClickMenu(e)}></Menu>
              <div className="contentbox">
                  <div className="title">{componentsDom&&componentsDom.label}</div>
                  {componentsDom&&componentsDom?.components}
              </div>
          </div>
      </WsContextProvider>
  )
}

export default Home