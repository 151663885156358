import {createContext, useEffect, useRef, useState} from "react";
import {wsUrl} from "../config";
import {notification} from "antd";
import { v4 } from 'uuid';
export const WsContext = createContext();

export const WsContextProvider = ({ children }) => {
  const uniqueId = v4();
  const [api, contextHolder] = notification.useNotification();
  const userObj=window.localStorage.getItem('user');
  const user =userObj? JSON.parse(userObj):{};
  const url=`${wsUrl}?username=${user?.username}&&uniqueId=${uniqueId}`
  const [appWs,setAppWs]=useState(null);
  const thisAppWs=useRef();
  const [pingIntervalObj,setPingIntervalObj]=useState();
  const [reCreateWsIntervalObj,setReCreateWsIntervalObj]=useState();
  const [wsMessage,setWsMessage]=useState();
  const pingInterval=()=>{
    setPingIntervalObj(setInterval(()=>{
      pingWs();
    },5500));
  }
  //两秒一次监听去重连
  const reCreateWs=()=>{
    clearInterval(pingIntervalObj);
    clearInterval(reCreateWsIntervalObj);
    setReCreateWsIntervalObj(setInterval(()=>{
      if(!thisAppWs.current||thisAppWs.current?.readyState!==thisAppWs.current?.OPEN){
        createWs();
      }
    },2000));
  }
  const createWs=()=>{
    clearInterval(pingIntervalObj);
    clearInterval(reCreateWsIntervalObj);
    const ws=new WebSocket(url);
    ws.onopen=()=>{
      pingInterval();
      console.log("ws连接成功");
      ws.send(userObj);
      setAppWs(ws);
      thisAppWs.current=ws;
    }
    ws.onclose=(e)=>{
      console.log("ws连接已断开",e);
    }
    ws.onerror=(e)=>{
      console.error("ws出错",e);
    }
    //监听信息来
    ws.addEventListener('message', function (event) {
      setWsMessage(JSON.parse(event.data))
    });
  }
  const pingWs=()=>{
    if(thisAppWs.current&&thisAppWs.current?.readyState===thisAppWs.current?.OPEN){
      const sendObj={code:200}
      if(user?.username){
        sendObj.username=user?.username;
      }
      console.log("开始pingWs")
      thisAppWs.current.send(JSON.stringify(sendObj));
    }
  }

  useEffect(()=>{
    if(!thisAppWs.current||thisAppWs.current?.readyState!==thisAppWs.current?.OPEN){
      createWs();
      reCreateWs();
    }
    return ()=>{
      clearInterval(pingIntervalObj);
      clearInterval(reCreateWsIntervalObj);
    }
  },[]);

  useEffect(()=>{
    if(wsMessage?.code?.toString()==='2000'){
        api.open({
          message: '用户上线',
          description:wsMessage?.username,
          showProgress: true,
          pauseOnHover:true,
        });
    }
  },[wsMessage])

  return (
    <WsContext.Provider value={{ appWs,wsMessage }}>
      {contextHolder}
      {children}
    </WsContext.Provider>
  );
};
