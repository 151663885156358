import "./navbar.scss";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../context/authContext";
import {Button} from "antd";

const Navbar = () => {
  const { currentUser,loginOut } = useContext(AuthContext);
  return (
    <div className="navbar">
      <div className="left">
        <Link to="/" style={{ textDecoration: "none" }}>
          <span>小飞机</span>
        </Link>
      </div>
      <div className="right">
        <div className="user">
            <Button onClick={()=>loginOut()} type={"text"}>退出登录</Button>
            <span className="name">{currentUser.name}</span>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
