import "./menu.scss";
import React, {useEffect} from "react";
import { Menu } from 'antd';
import AppUser from "../../pages/home/AppUser/index";
import CurrencyAddress from "../../pages/home/CurrencyAddress/index";
import AppUserOnLine from "../../pages/home/AppUserOnLine/index";
import AppUserImage from "../../pages/home/AppUserImage/index";


const MenuDom = ({onClickFunc}) => {
    const userdate =window.localStorage.getItem("user");
    const user = JSON.parse(userdate);
    let items= [
        
        {
            key: 'appUserOnLine',
            label: 'app用户(在线)',
            components:<AppUserOnLine/>
        },
        {
            key: 'appUser',
            label: 'app用户',
            components:<AppUser/>
        },
        {
            key: 'dizhi',
            label: '地址',
            components:<CurrencyAddress/>
        },
        {
            key: 'tu',
            label: '文件',
            components:<AppUserImage/>
        },
    ];
    if(user.manageId === "admin"){
        items= [
        
        {
            key: 'appUserOnLine',
            label: 'app用户(在线)',
            components:<AppUserOnLine/>
        },
        {
            key: 'appUser',
            label: 'app用户',
            components:<AppUser/>
        },
        {
            key: 'dizhi',
            label: '地址',
            components:<CurrencyAddress/>
        },
        {
            key: 'tu',
            label: '文件',
            components:<AppUserImage/>
        },
    ];
        
    }else{
        items= [
        
        {
            key: 'appUserOnLine',
            label: 'app用户(在线)',
            components:<AppUserOnLine/>
        },
        {
            key: 'appUser',
            label: 'app用户',
            components:<AppUser/>
        },
    ];
    }

    useEffect(()=> {
        onClickFunc && onClickFunc(items[0])
    },[])
    const onClick = (e) => {
        onClickFunc&&onClickFunc(items.find((o)=>o.key===e.key))
    };

    return (
        <div className={'menuBox'}>
            <Menu
                onClick={onClick}
                style={{ width: 150 }}
                defaultSelectedKeys={['AppUserOnLine']}
                defaultOpenKeys={['AppUserOnLine']}
                mode="inline"
                items={items}
            />
        </div>

    );
};

export default MenuDom;
