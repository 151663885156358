import { createContext, useEffect, useState } from "react";
import {httpUrl} from "../config";
import makeRequest from "../axios";
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const user =window.localStorage.getItem("user");
  const [currentUser, setCurrentUser] = useState(
      user&&user!=='undefined'?JSON.parse(user):null
  );
  const login =  (inputs) => {
    makeRequest.post(`${httpUrl}auth/login`, inputs, {
      withCredentials: true, // 跨域请求携带cookie, 后端必须cors指定域名
    }).then((res)=>{
      setCurrentUser(res.data);
      window.location.href='/'
    }).catch(()=>{

    })
  };
  const loginOut =() => {
    makeRequest.post(`${httpUrl}auth/logout`, {
      withCredentials: true,
    }).then(()=>{
      setCurrentUser(null)
    });
  };
  useEffect(() => {
    localStorage.setItem("user", JSON.stringify(currentUser));
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login,loginOut }}>
      {children}
    </AuthContext.Provider>
  );
};
